export const isPostTitleDimmed = ({
  isRecentActivityCatsEnabled,
  type,
  isPostSeen,
}: {
  isRecentActivityCatsEnabled: boolean;
  type: 'postPage' | 'footerPost' | 'recentPostsWidget';
  isPostSeen: boolean;
}) => {
  if (type === 'postPage' || !isRecentActivityCatsEnabled) {
    return false;
  }

  return isPostSeen;
};
